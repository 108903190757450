body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-95{width: 95% !important;}
.w-90{width: 90% !important;}
.w-85{width: 85% !important;}
.w-80{width: 80% !important;}
.w-75{width: 75% !important;}
.w-70{width: 70% !important;}
.w-65{width: 65% !important;}
.w-60{width: 60% !important;}
.w-55{width: 55% !important;}
.w-50{width: 50% !important;}
.w-45{width: 45% !important;}
.w-40{width: 40% !important;}
.w-35{width: 35% !important;}
.w-30{width: 30% !important;}
.w-25{width: 25% !important;}
.w-20{width: 20% !important;}
.w-15{width: 15% !important;}
.w-10{width: 10% !important;}
.w-5{width: 5% !important;}
.w-3{width: 3% !important;}
.w-2{width: 2% !important;}
.w-1{width: 1% !important;}

.mx-autos{margin-right: auto!important; margin-left: auto!important;}


