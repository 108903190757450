npm.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.selectbox {
  display: block;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.plotcom{
  width: 450px;
  display: block;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  border-color: rgba(171, 171, 171, 0.7);
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.plotcom:hover {
  border: 1px solid rgba(93, 93, 93, 0.1);
}

.dflex{
  display: flex;
}

.border-lines {
  border-top: 2px solid rgba(0, 0, 0, 0.4);
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  border-left: 2px solid rgba(0, 0, 0, 0.4);
}

.upper-border{
  border-top: 1px solid rgba(117, 117, 117, 0.3);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
